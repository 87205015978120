<template>
    <div class="create-company-main">
        <h3 class="create-company-main__title">Основные</h3>
        <div class="create-company-main__add-logo">
            <img :src="form.logo"
                 class="create-company-main__image"
                 alt="logo"
                 v-if="form.logo">
            <div class="create-company-main__upload"
                 v-else>
                <input type="file"
                       class="create-company-main__upload-inp"
                       accept=".jpg, .jpeg, .png"
                       @change="upload">
                <img src="@/assets/images/icons/camera.svg"
                     class="create-company-main__upload-icon" alt="camera">
            </div>
            <div class="create-company-main__add-logo-content">
                <p class="create-company-main__add-logo-description" v-if="form.logo">Логотип компании</p>
                <p class="create-company-main__add-logo-description" v-else>Добавьте логотип компании</p>
                <div v-if="form.logo" class="create-company-main__add-logo-edit">
                    Изменить
                    <input type="file"
                           class="create-company-main__add-logo-edit-inp"
                           accept=".jpg, .jpeg, .png"
                           @change="upload">
                </div>
                <p class="create-company-main__error" v-if="imageSizeExceeded">Неправильный размер файла</p>
                <p class="create-company-main__error" v-if="formatError">Неправильный формат изображения</p>
            </div>
        </div>

        <div class="create-company-main__inp-group">
            <div class="create-company-main__inp-wrapper">
                <VCustomInput
                    type="text"
                    placeholder="Название компании*"
                    v-model="form.name"
                    :error="!form.name && validation"
                    :maxlength="60"
                    class="create-company-main__inp"/>
                <p class="create-company-main__error" v-if="!form.name && validation">Заполните обязательное поле</p>
            </div>
            <div class="create-company-main__inp-wrapper">
                <VCustomInput
                    type="text"
                    placeholder="ИНН*"
                    v-mask="'0000-00000-0'"
                    v-model="form.inn"
                    :error="(!form.inn && validation) || errorText?.inn"
                    :maxlength="60"
                    class="create-company-main__inp"/>
                <p class="create-company-main__error" v-if="!form.inn && validation">Заполните обязательное поле</p>
                <p class="create-company-main__error" v-if="errorText?.inn">{{errorText?.inn[0]}}</p>
            </div>
            <div class="create-company-main__inp-wrapper">
                <VCustomInput
                    type="text"
                    placeholder="ОГРН*"
                    v-mask="'0-00-00-0000000-0'"
                    v-model="form.ogrn"
                    :error="(!form.ogrn && validation) || errorText?.ogrn"
                    :maxlength="60"
                    class="create-company-main__inp"/>
                <p class="create-company-main__error" v-if="!form.ogrn && validation">Заполните обязательное поле</p>
                <p class="create-company-main__error" v-if="errorText?.ogrn">{{errorText?.ogrn[0]}}</p>
            </div>
        </div>

        <VEditor
            v-model="form.description"
            placeholder="Описание компании*"
            :error="!form.description && validation"
            :maxlength="10"
            class="create-company-main__textarea"/>
        <p class="create-company-main__error" v-if="!form.description && validation">Заполните обязательное поле</p>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    validation: {
        type: Boolean,
        default: false
    },
    errorText: {
        type: Object
    }
});

const emit = defineEmits(["update:modelValue"]);

const form = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const imageSizeExceeded = ref(false)
const formatError = ref(false)
const maxImageSize = 10000000

const upload = (e) => {
    form.value.logo = ''
    let reader = new FileReader();
    if (e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/jpeg') {
        formatError.value = true
        return
    } else {
        formatError.value = false
    }
    if (e.target.files[0].size < maxImageSize) {
        imageSizeExceeded.value = false
        reader.onload = e => form.value.logo = e.target.result
        reader.readAsDataURL(e.target.files[0]);
    } else {
        imageSizeExceeded.value = true
    }
}
</script>

<style scoped lang="scss">
.create-company-main {
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px #0000000A;


    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__add-logo {
        margin-top: 16px;
        display: flex;
        align-items: center;
    }

    &__upload {
        position: relative;
        width: 64px;
        height: 64px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed $blue;

        &_error {
            border-color: $red;
        }
    }

    &__image {
        width: 64px;
        height: 64px;
        border-radius: 8px;
        object-fit: contain;
        border: 1px solid $light-gray-1;
    }

    &__upload-inp {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
    }

    &__add-logo-content {
        margin-left: 16px;
    }

    &__add-logo-description {
        width: 135px;
        font-size: 14px;
        font-weight: 400;
    }

    &__add-logo-edit {
        position: relative;
        margin-top: 6px;
        color: $blue;
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        cursor: pointer;

        &:hover {
            color: #074CA7;
        }

        &:active {
            color: #0A3489;
        }
    }

    &__add-logo-edit-inp {
        position: absolute;
        left: 0;
        top: -30px;
        width: 100%;
        height: 80px;
        opacity: 0;
        cursor: pointer;
        z-index: 99;
    }

    &__inp-group {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
    }

    &__inp-wrapper {
        width: 250.66px;
    }

    &__inp {
        width: 100%;
    }

    &__textarea {
        margin-top: 16px;
        width: 100%;
        //height: 200px;
    }

    &__error {
        margin-top: 4px;
        color: $red;
        font-size: 14px;
    }
}
</style>

<template>
  <div class="personal-data-processing">
    <h3 class="personal-data-processing__title">
      Обработка персональных данных
    </h3>
    <p class="personal-data-processing__description">
      Загрузите файл с обработкой персональных данных
    </p>
    <div class="personal-data-processing__upload-file-wrapper">
      <label v-if="!props?.modelValue" class="personal-data-processing__upload-file-btn">
        <input class="personal-data-processing__file-input" @change="uploadPDF" type="file" accept="application/pdf" />
        <img src="@/assets/images/icons/upload.svg" alt="upload" />
        Загрузить файл
      </label>
      <div v-if="props?.modelValue" class="personal-data-processing__uploaded-file">
        <img class="personal-data-processing__uploaded-file-icon" src="@/assets/images/pdf-icon.png" alt="pdf-icon"
          @click="previewFile" />
        <div v-if="typeof props?.modelValue === 'object'" class="personal-data-processing__uploaded-file-properties" @click="previewFile">
          <div class="personal-data-processing__uploaded-file-text">
            <span class="personal-data-processing__uploaded-file-name">Политика обработки персональных данных</span><span class="personal-data-processing__uploaded-file-extension">pdf</span>
          </div>
          <span class="personal-data-processing__uploaded-file-size">{{ ((uploadedFile?.size) / (1024 *
        1024)).toFixed(2) }} МБ</span>
        </div>
        <div v-else class="personal-data-processing__uploaded-file-properties" @click="previewFile">
          <div class="personal-data-processing__uploaded-file-text">
          <span class="personal-data-processing__uploaded-file-name">Персона...</span><span class="personal-data-processing__uploaded-file-extension">pdf</span>
          </div>
          <span class="personal-data-processing__uploaded-file-size">1 файл</span>
        </div>
        <img class="personal-data-processing__uploaded-file-delete" src="@/assets/images/icons/delete-red.svg"
          alt="delete-red" @click="deleteFile" />
      </div>
    </div>
    <div class="personal-data-processing__errors" v-if="errorText || isRequired || isError">
      <div class="personal-data-processing__error-text" v-if="errorText && isError">{{ errorText }}</div>
      <div class="personal-data-processing__error-text" v-if="isRequired && !uploadedFile">{{ requiredMessage ||
        'Загрузите файл' }}</div>
    </div>
  </div>
</template>

<script setup>
import {  ref, onMounted } from "vue";

const uploadedFile = ref(null);

const props = defineProps({
  modelValue: {
    type: [Object, String],
    default: null
  },
  errorText: {
    type: String,
  },
  isRequired: {
    type: Boolean,
    default: false
  },
  requiredMessage: {
    type: String,
  },
  isError: {
    type: Boolean,
    default: false
  },
});

const emit = defineEmits(["update:modelValue", "deleteFile", "change"]);

const uploadPDF = (e) => {
  const file = e.target.files[0];
  emit("change", file)
  if (file.type !== "application/pdf") return;
  uploadedFile.value = file;
  emit("update:modelValue", file)
};

const deleteFile = () => {
  uploadedFile.value = null
  emit("deleteFile")
  emit("update:modelValue", null)
}

const previewFile = () => {
  if (typeof props?.modelValue === 'string') {

    const viewFileURL = props?.modelValue
    let link = document.createElement('a');
    link.setAttribute('type', '_blank')
    link.setAttribute('name', 'Политика обработки персональных данных')
    link.setAttribute('id', 'Политика обработки персональных данных')
    link.href = `${viewFileURL}`
    link.click();
    link.remove();
  } else {
    const viewFileURL = URL.createObjectURL(props?.modelValue)
    let link = document.createElement('a');
    link.setAttribute('type', '_blank')
    link.setAttribute('name', 'Политика обработки персональных данных')
    link.setAttribute('id', 'Политика обработки персональных данных')
    link.href = `${viewFileURL}`
    link.click();
    link.remove();
  }
}

onMounted(() => {
  if (props?.modelValue) uploadedFile.value = props?.modelValue;
})
</script>

<style scoped lang="scss">
.personal-data-processing {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 2px 2px 8px 0px #0000000a;

  &__title {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24.2px;
  }

  &__description {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 15px;
    line-height: 18.15px;
    color: $dark-blue;
  }

  &__upload-file-wrapper {
    display: flex;
    align-items: center;
    padding: 4px 12px 4px 12px;
    width: 187px;
    height: 56px;
    background-color: $primary-blue;

    border-radius: 8px;
  }

  &__upload-file-btn {
    position: relative;
    height: 56px;
    padding: 0 9.5px;
    border-radius: 8px;
    color: $blue;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    img {
      width: 24px;
      margin-right: 8px;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
    }
  }

  &__uploaded-file {
    display: flex;
    align-items: center;
    gap: 12px
  }

  &__uploaded-file-icon {
    display: block;
    width: 24px;
    height: 24px;
    object-fit: contain;
    flex: 0 0 auto;
    cursor: pointer;
  }

  &__uploaded-file-properties {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 95px;

    // margin-right: 5px;
  }

  &__uploaded-file-text {
    display: flex;
    margin-bottom: 4px;

  }

  &__uploaded-file-name {
    font-size: 14px;
    line-height: 19.94px;
    // max-width: 70px;
    // width: max-content;
    width: min-content;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    // flex: 0 0 auto;
  }

  &__uploaded-file-extension {
    position: relative;
    // left: -5px;
    font-size: 14px;
    line-height: 19.94px;
  }

  &__uploaded-file-size {
    display: block;
    font-size: 12px;
    line-height: 14.52px;
    color: $gray;
  }

  &__uploaded-file-delete {
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &__errors {
    margin-top: 16px;
  }

  &__error-text {
    font-size: 14px;
    line-height: 16.94px;
    color: $red;
  }
}
</style>

<template>
    <div class="treaty">
        <div class="treaty__header">
            <h3 class="treaty__title">Договор</h3>
            <VCheckbox
                v-model="date.hasContract"
                @update:modelValue="changeCheckbox"
                class="treaty__checkbox"/>
        </div>

        <div
            class="treaty__term-contract"
            :class="{'treaty__term-contract_error validation-error': (!date.date.start || !date.date.end) && validation}"
            v-if="date.hasContract">
            <div class="treaty__term-contract-title">Срок действия договора*</div>
            <TermContractPicker
                v-model="date"
                class="treaty__term-contract-picker"/>
        </div>
        <p class="treaty__error" v-if="!date.date.end && validation && date.hasContract && !errorText">Заполните обязательное поле</p>
        <p class="treaty__error" v-if="errorText">{{errorText}}</p>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";
import TermContractPicker from '@/components/CreateCompany/TermContractPicker'

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    validation: {
        type: Boolean,
        default: false
    },
    errorText: {
        type: String
    }
})

const emit = defineEmits(["update:modelValue"]);

const treaty = ref(true)
const showTermContractPicker = ref(false)

const date = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const changeCheckbox = (value) => {
    if (!value) {
        props.modelValue.date.start = ''
        props.modelValue.date.end = ''
    }
}
</script>

<style scoped lang="scss">
.treaty {
    width: 100%;
    padding: 29px 24px 24px 24px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: 2px 2px 8px 0px #0000000A;
    user-select: none;

    &__title {
        font-size: 20px;
        font-weight: 500;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__checkbox {
        margin-top: 2px;
        margin-left: 17px;
    }

    &__term-contract {
        position: relative;
        width: 100%;
        height: 48px;
        margin-top: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &_error {
            //border: 1px solid $red !important;

            &::v-deep(.term-contract-picker__inp-wrapper) {
                border: 1px solid $red !important;
            }
        }
    }

    &__term-contract-picker {
        z-index: 99;
    }

    &__term-contract-title {
        font-size: 14px;
        font-weight: 400;
    }

    &__term-contract-dates {
        display: flex;
    }

    &__term-contract-date {
        width: 110px;
        height: 32px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        font-size: 14px;
        cursor: pointer;
    }

    &__term-contract-arrow {
        margin: 0 12px;
    }

    &__error {
        max-width: 500px;
        margin-top: 4px;
        color: $red;
        font-size: 14px;
    }
}
</style>

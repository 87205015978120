import {ref} from 'vue';

const getOffsetTop = (element) => {
    let offset = element.offsetTop;
    if (element.offsetParent) {
        offset += getOffsetTop(element.offsetParent);
    }
    return offset;
}

const useOffsetTop = (element) => {
    const getElement = element ? element : document.querySelector('.validation-error')
    const offsetFromTop = ref(0);

    if (getElement) {
        offsetFromTop.value = getOffsetTop(getElement) - 30;
    }

    window.scrollTo(0, offsetFromTop.value);

    return offsetFromTop;
}

export default useOffsetTop

<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="$emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close">
        </div>

        <h3 class="modal-body__title">Отменить изменения?</h3>
        <p class="modal-body__description">Вы действительно хотите отменить изменения?</p>

        <div class="modal-body__btns">
            <VButton
                label="Да"
                clickEffect
                class="modal-body__btn"
                @click="$emit('cancelChanges')"/>

            <VButton
                label="Нет"
                bg="#E4E7EE"
                bgHover="#BBC4CD"
                color="#1E3959"
                colorHover="#1E3959"
                class="modal-body__btn"
                @click="$emit('close')"/>
        </div>
    </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__title {
        color: $dark;
        font-size: 20px;
        font-weight: 600;
    }

    &__description {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
    }

    &__btns {
        width: 100%;
        margin-top: 24px;
    }

    &__btn {
        width: 100%;

        &:not(:first-child) {
            margin-top: 12px;
        }

        &:last-child {
            &:active {
                background-color: #8F9CAC !important;
            }
        }
    }
}
</style>

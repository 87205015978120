<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="$emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close">
        </div>

        <h3 class="modal-body__title">Закрытие договора</h3>
        <p class="modal-body__description">Если вы закроете договор:</p>

        <ul class="modal-body__list">
            <li>все активные вакансии компании будут закрыты</li>
            <li>отклики получат отказ</li>
            <li>сотрудники компании будут заблокированы</li>
            <li>чаты будут закрыты</li>
        </ul>

        <div class="modal-body__btns">
            <VButton
                bg="#DD4646"
                bgHover="#D10E0E"
                color="#fff"
                label="Перейти к закрытию"
                class="modal-body__btn"
                @click="$emit('goToClosing')"/>

            <VButton
                bg="#E4E7EE"
                color="#1E3959"
                bgHover="#BBC4CD"
                colorHover="#1E3959"
                label="Отменить"
                class="modal-body__btn"
                @click="$emit('close')"/>
        </div>
    </div>
</template>

<script setup></script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
    }

    &__description {
        margin-top: 8px;
        color: $dark;
        font-size: 16px;
        font-weight: 400;
    }

    &__list {
        max-width: 252px;
        margin-top: 16px;
        padding-left: 20px;
        text-align: left;

        li {
            font-size: 16px;

            &:not(:first-child) {
                margin-top: 12px;
            }
        }
    }


    &__btns {
        width: 100%;
        margin-top: 24px;
    }

    &__btn {
        width: 100%;

       &:first-child {
           &:active {
               background-color: #A61F1F !important;
           }
       }

        &:not(:first-child) {
            margin-top: 12px;

            &:active {
                background-color: $gray !important;
            }
        }
    }
}
</style>

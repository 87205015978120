<template>
    <div class="term-contract-picker">
        <DatePicker
            v-model="date.date.start"
            mode="date"
            :masks="masks"
            :popover="popover"
            class="term-contract-picker__picker"
        >
            <template v-slot="{ togglePopover, inputValue, inputEvents }">
                <div class="term-contract-picker__group">
                    <div class="term-contract-picker__inps">
                        <p class="term-contract-picker__end-text">От</p>

                        <div class="term-contract-picker__inp-wrapper">
                            <input
                                class="term-contract-picker__inp"
                                placeholder="дд.мм.гггг"
                                v-mask="'00.00.0000'"
                                :value="inputValue"
                                v-on="inputEvents"
                            />

                            <div @click="() => togglePopover()" class="term-contract-picker__inp-icon term-contract-picker__inp-icon_active">
                                <img src="@/assets/images/icons/calendar.svg" alt="calendar">
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </DatePicker>
        <DatePicker
            v-model="date.date.end"
            mode="date"
            :masks="masks"
            :popover="popover"
            class="term-contract-picker__picker"
        >
            <template v-slot="{ togglePopover, inputValue, inputEvents, isDragging }">
                <div class="term-contract-picker__group">
                    <div class="term-contract-picker__inps">
                        <p class="term-contract-picker__end-text">До</p>

                        <div class="term-contract-picker__inp-wrapper" :class="{'term-contract-picker__inp-wrapper_disabled': !date.date.start}">
                            <input
                                class="term-contract-picker__inp"
                                :class="{'term-contract-picker__inp_disabled': !date.date.start}"
                                placeholder="дд.мм.гггг"
                                v-mask="'00.00.0000'"
                                :value="inputValue"
                                v-on="inputEvents"
                                :disabled="!date.date.start"
                            />

                            <div v-if="date.date.start" @click="() => togglePopover()" class="term-contract-picker__inp-icon term-contract-picker__inp-icon_active">
                                <img src="@/assets/images/icons/calendar.svg" alt="calendar">
                            </div>

                            <div v-else class="term-contract-picker__inp-icon">
                                <img src="@/assets/images/icons/calendar.svg" alt="calendar">
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </DatePicker>
    </div>
</template>

<script setup>
import {ref, computed, onMounted, reactive} from "vue";
import moment from "moment";
import {DatePicker} from 'v-calendar';
import 'v-calendar/dist/style.css';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    }
})

const masks = {
    input: 'DD.MM.YYYY',
}

const range = reactive({
    start: '',
    end: '',
})

const emit = defineEmits(["update:modelValue", "close"]);

const date = computed({
    get: () => props.modelValue,
    set: (value) => emit("update:modelValue", value),
});

const popover = ref({
    visibility: '',
    placement: 'bottom',
});
</script>

<style scoped lang="scss">
.term-contract-picker {
    display: flex;

    &__group {
        display: flex;
    }

    &__inps {
        display: flex;
        align-items: center;

        p {
            color: $gray;
            margin-right: 8px;
        }

        &:not(:first-child) {
            margin-left: 32px;
        }
    }

    &__end-text {
        margin-left: 32px;
        font-size: 14px;
        font-weight: 400;
    }

    &__inp-wrapper {
        width: 190px;
        height: 40px;
        padding: 0 16px;
        border-radius: 8px;
        background-color: $light;
        display: flex;
        align-items: center;

        &_disabled {
            cursor: no-drop;
        }
    }

    &__inp {
        width: 100%;
        height: 100%;
        font-size: 14px;
        background-color: transparent;

        &::placeholder {
            color: $dark-blue;
        }

        &_disabled {
            &::placeholder {
                opacity: .3;
            }
        }
    }

    &__inp-icon {
        &_active {
            cursor: pointer;
        }
    }

    &__picker {

        &::v-deep(.vc-weekday) {
            color: $gray;
            font-size: 14px;
            font-weight: 400;
        }

        &::v-deep(.vc-arrow) {
            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: #0D5FCB;
                }
            }
        }

        &::v-deep(.vc-title) {
            font-size: 16px;
            font-weight: 400;
        }

        &::v-deep(.vc-container) {
            border: none;
        }

        &::v-deep(.vc-popover-caret) {
            display: none;
        }

        &::v-deep(.vc-day) {
            width: 36px !important;
            height: 36px !important;

            .vc-day-content {
                width: 100% !important;
                height: 100% !important;
                border-radius: 4px;
                font-size: 16px !important;
                font-weight: 400 !important;
                font-family: 'Inter', sans-serif !important;

                &:hover, &:focus {
                    background-color: #2C72FF !important;
                    color: #fff !important;
                }
            }
        }

        &::v-deep(.vc-day-layer) {
            .vc-highlight {
                border-radius: 4px !important;
                background-color: $blue !important;
                width: 36px !important;
                height: 36px !important;
            }

            .vc-highlight-base-start {
                border-radius: 0 !important;
                background-color: rgba(13, 95, 203, 0.45) !important;
                opacity: 0.3 !important;
            }

            .vc-highlight-base-end {
                border-radius: 0 !important;
                background-color: rgba(13, 95, 203, 0.45) !important;
                opacity: 0.3 !important;
            }


            .vc-highlight-base-middle {
                border-radius: 0 !important;
                background-color: rgba(13, 95, 203, 0.45) !important;
                opacity: 0.3 !important;
            }

        }
    }
}
</style>



<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="$emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close">
        </div>

        <h3 class="modal-body__title">Подтверждение закрытия договора</h3>

        <VCustomInput
            v-model="companyName"
            placeholder="Название компании*" class="modal-body__inp"
            :error="errorInp"
        />
        <p class="modal-body__error-text" v-if="companyNameRequiredError && !companyName">Заполните
            обязательное поле</p>
        <p class="modal-body__error-text" v-if="errorText && !companyNameRequiredError && companyName">{{
                errorText
            }}</p>

        <div class="modal-body__text-block">
            <p class="modal-body__text-block-label">Для откликнувшихся:</p>
            Благодарим за ваш отклик.
            Мы внимательно ознакомились с вашим резюме. К сожалению, на данном этапе мы не готовы предложить вам работу.
            Искренне желаем удачи в поисках работы!
        </div>

        <div class="modal-body__btns">
            <VButton
                bg="#DD4646"
                bgHover="#D10E0E"
                color="#fff"
                label="Закрыть договор"
                class="modal-body__btn"
                @click="closeContract"/>

            <VButton
                color="#1E3959"
                colorHover="#1E3959"
                bg="#E4E7EE"
                bgHover="#BBC4CD"
                label="Отменить"
                class="modal-body__btn"
                @click="$emit('close')"/>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "vue";

const props = defineProps({
    errorText: String
})

const companyName = ref('')
const companyNameRequiredError = ref(false)

const emit = defineEmits(['closeContract']);

const errorInp = computed(() => {
    return (!!props.errorText && companyName.value) || (companyNameRequiredError.value && !props.errorText.value && !companyName.value)
})

const closeContract = () => {
    if (!companyName.value) {
        companyNameRequiredError.value = true
        return
    }
    companyNameRequiredError.value = false
    emit('closeContract', companyName.value)
}
</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
    }

    &__description {
        margin-top: 12px;
        color: $gray;
        font-size: 16px;
    }

    &__inp {
        width: 100%;
        margin-top: 16px;
    }

    &__error-text {
        width: 100%;
        margin-top: 4px;
        color: $red;
        text-align: left;
        font-size: 14px;
    }

    &__label {
        width: 100%;
        margin-top: 16px;
        color: $gray;
        text-align: left;
        font-size: 14px;
    }

    &__text-block {
        width: 100%;
        padding: 16px;
        margin-top: 16px;
        border-radius: 8px;
        background-color: $light;
        color: $gray;
        text-align: left;
        font-size: 14px;
        border: 1px solid $light-gray-1;
    }

    &__text-block-label {
        margin-bottom: 4px;
        color: $dark-blue;
        font-size: 12px;
        font-weight: 400;
    }

    &__btns {
        width: 100%;
        margin-top: 24px;
    }

    &__btn {
        width: 100%;

        &:active {
            background-color: #A61F1F !important;
        }

        &:not(:first-child) {
            margin-top: 12px;

            &:active {
                background-color: $gray !important;
            }
        }
    }
}
</style>
